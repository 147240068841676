<template>

  <div class="root">
    <div class="container">
      <div class="logo">
        <img class="icon" src="../assets/img/icon.jpg" />
      </div>

      <div class="title">{{systemName}}管理系统</div>

      <div class="inputs" >

        <input type="text" name="email" v-model="username" class="username_input" placeholder="用户名" />

        <input type="password"  v-model="password" name="password" placeholder="密码" />

        <input v-if="this.captchaEnable==true" type="password"  v-model="inputCode" name="password" class="code-input" placeholder="验证码" />


        <button type="submit" v-on:click="getShareInfo()">LOGIN</button>
      </div>

    </div>


    <div class="footer">
      <span>Copyright © 程序员田同学</span>
    </div>

  </div>

</template>

<script>
import { getSing} from "@/api/test";

import wx from "weixin-js-sdk"
export default {

  name: "login",

  data() {
    return {
      systemName:'',
      username: '',
      password: '',
      code: '',
      uuid: '',
      inputCode:'',
      info: null,
      captchaEnable:''
    }

  },

  created() {

   // this.getShareInfo();
    console.log("redad.....")
    wx.ready(() => {
      var shareData = {
        title: "每日新闻",
        desc: "2022年12月20日21:47:55每日新闻",
        link: window.location.href,
        imgUrl: "https://blogobs.88688.team/blog/l-logo-y.jpg"
      };
      console.log("shareDate",shareData)
      //自定义“分享给朋友”及“分享到QQ”按钮的分享内容
      wx.updateAppMessageShareData(shareData);
      //自定义“分享到朋友圈”及“分享到 QQ 空间”按钮的分享内容（1.4.0）
      wx.updateTimelineShareData(shareData);
    });
  },
  methods: {

      getShareInfo() {
        //获取url链接（如果有#需要这么获取）
        var url = encodeURIComponent(window.location.href.split("#")[0]);
        //获取url链接（如果没有#需要这么获取）
        // var url = encodeURIComponent(window.location.href);
      //  var url1="http://c5ct54.natappfree.cc/#/test"
        getSing({"url":url}).then(res => {
          wx.config({
            debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
            appId: res.datas.appId, // 必填，公众号的唯一标识
            timestamp: parseInt(res.datas.timestamp), // 必填，生成签名的时间戳
            nonceStr: res.datas.nonceStr, // 必填，生成签名的随机串
            signature: res.datas.signature, // 必填，签名
            jsApiList: [
              "updateAppMessageShareData",
              "updateTimelineShareData"
            ] // 必填，需要使用的 JS 接口列表
          });



          //错误了会走 这里
          wx.error(function (res) {
            console.log("微信分享错误信息", res);
          });
        });
      },


  }

}
</script>

<style scoped>




.container {
  width: 350px;
  height: 520px;
  background: #ecf0f3;
  padding: 40px;
  border-radius: 20px;
  box-sizing: border-box;
  box-shadow: 14px 14px 20px #cbced1, -14px -14px 20px white;
  position: absolute;
  /*居中显示*/
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.logo {
  height: 100px;
  width: 100px;
  background-size: 160px;
  background-position: -30px;
  margin: auto;
  border-radius: 50%;
  box-sizing: border-box;
  box-shadow: 7px 7px 10px #cbced1, -7px -7px 10px white;
}

.icon {
  height: 100px;
  width: 100px;
  border-radius: 50%;
}
.title {
  margin-top: 20px;
  margin-left: 65px;
  font-weight: 700;
  font-size: 1.2rem;
  color: #1da1f2;
  letter-spacing: 1px;
}

/*输入部分*/
.inputs {
  text-align: left;
  margin-top: 30px;
}

label,
input,
button {
  display: block;
  width: 100%;
  padding: 0;
  border: none;
  outline: none;
  box-sizing: border-box;
}

label {
  margin-bottom: 4px;
  font-size: 16px;
}

label:nth-of-type(2) {
  margin-top: 14px;
}

input {
  background: #ecf0f3;
  padding: 10px;
  padding-left: 20px;
  font-size: 14px;
  border-radius: 50px;
  height: 50px;
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white;
}

input::placeholder {
  color: gray;
}
.username_input{
  margin-bottom: 10px;
}
button {
  margin-top: 20px;
  background: #1da1f2;
  height: 40px;
  border-radius: 20px;
  cursor: pointer;
  font-weight: 900;
  font-family: sans-serif;
  letter-spacing: 1px;
  box-shadow: 6px 6px 6px #cbced1, -6px -6px 6px white;
}
button:hover {
  box-shadow: none;
}

/*验证码输入框*/
.code-input{
  width: 120px;
  margin: 10px 0px;

}


.login-code{
  position: absolute;
  right: 70px;
  bottom: 150px;
}

/*图片验证码*/
.login-code {
  width: 25%;
  height: 30px;
  float: right;
  margin: 10px 20px;

}
/**
版权信息
 */
.footer{
  position: absolute;
  left: 44%;
  top: 90%;
}
</style>