import request from '@/utils/request'

// 登录
export const getSing = (data) => {
    return request({
        url: '/wechat/getSing/public',
        method: 'post',
        data: data
    })
}

